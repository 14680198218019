/* eslint-disable */

import axios from "axios";

const api = axios.create({
    baseURL: "http://localhost:3306/",
    //baseURL: "https://pinsdlp.com",
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

export default api;
