import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/axios";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import {
    mdiChevronDown,
    mdiArrowUpThin,
    mdiArrowRightBold,
    mdiMagnify,
    mdiTwitter,
    mdiInstagram,
    mdiArrowLeftBold,
    mdiSort,
    mdiChevronUp,
    mdiCloseCircle,
    mdiDeleteEmpty,
    mdiFilterMultiple,
} from "@mdi/js";

const vuetify = createVuetify({
    icons: {
        defaultSet: "mdi",
        aliases: {
            ...aliases,
            chevronDown: mdiChevronDown,
            chevronUp: mdiChevronUp,
            arrowUp: mdiArrowUpThin,
            arrowRight: mdiArrowRightBold,
            arrowLeft: mdiArrowLeftBold,
            magnify: mdiMagnify,
            twitter: mdiTwitter,
            instagram: mdiInstagram,
            sort: mdiSort,
            close: mdiCloseCircle,
            delete: mdiDeleteEmpty,
            filterMultiple: mdiFilterMultiple,
        },
        sets: { mdi },
    },
});
const app = createApp(App);
app.use(vuetify);
app.use(router);
app.use(store);
app.mount("#app");
