<template>
    <div id="app">
        <NavBar />
        <BackToTop />
        <div class="auth-wrapper">
            <div class="auth-inner">
                <router-view></router-view>
            </div>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FooterComponent from "./components/FooterComponent.vue";
import BackToTop from "./components/BackToTop.vue";
import { mapActions } from "vuex";
import api from "./axios";
import "./styles/index.scss";
export default {
    name: "App",
    components: {
        NavBar,
        FooterComponent,
        BackToTop,
    },
    mounted() {
        const token = sessionStorage.getItem("token");
        if (token) {
            try {
                // C'est là que sa foire go chnger ça
                const req = api.get("/api/users/", {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });

                req.catch(() => {
                    // this.logout();
                    console.log("Coucou");
                });
            } catch (error) {
                console.log(error);
            }
        }
    },
    created() {
        // this.getPinsDataLimit();
        this.getPinsData();
        this.getArticlesData();
        this.getAffichesData();
    },

    methods: {
        ...mapActions(["getArticlesData"]),
        ...mapActions(["getPinsData"]),
        ...mapActions(["getAffichesData"]),
        //...mapActions(["getPinsDataLimit"]),
        logout() {
            sessionStorage.removeItem("role"),
                sessionStorage.removeItem("username"),
                sessionStorage.removeItem("userId"),
                sessionStorage.removeItem("token"),
                this.$router.push("/login");
        },
    },
};
</script>
