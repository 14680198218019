import { createStore } from "vuex";
import axios from "axios";

const store = createStore({
    state() {
        return {
            allPins: [],
            allArticles: [],
            allAffiches: [],
            searchQuery: "",
        };
    },
    mutations: {
        setPinsData(state, payload) {
            state.allPins = payload;
        },
        setArticlesData(state, payload) {
            state.allArticles = payload;
        },
        setAffichesData(state, payload) {
            state.allAffiches = payload;
        },
        setSearchQuery(state, searchQuery) {
            state.searchQuery = searchQuery;
        },
    },
    actions: {
        async getArticlesData(context) {
            try {
                const [articlesResponse] = await Promise.all([
                    axios.get("https://www.pinsdlp.com/api/articles/"),
                    // axios.get("http://localhost:3000/api/articles/"),
                ]);
                const articles = articlesResponse.data.ListeArticles.map(
                    (article) => {
                        return {
                            ...article,
                        };
                    }
                );
                context.commit("setArticlesData", articles);
                //console.log(articles);
            } catch (error) {
                console.log(error);
            }
        },
        async getAffichesData(context) {
            try {
                const [affichesResponse] = await Promise.all([
                    axios.get("https://www.pinsdlp.com/api/affiches/"),
                    //  axios.get("http://localhost:3000/api/affiches/"),
                ]);
                const affiches = affichesResponse.data.ListeAffiches.map(
                    (affiche) => {
                        return {
                            ...affiche,
                        };
                    }
                );
                context.commit("setAffichesData", affiches);
                //   console.log("data affiche ->", affiches);
            } catch (error) {
                console.log(error);
            }
        },
        async getPinsData(context) {
            try {
                const [pinsResponse, categoriesResponse, shopsResponse] =
                    await Promise.all([
                        // axios.get("http://localhost:3000/api/pins/"),
                        // axios.get("http://localhost:3000/api/categories/"),
                        // axios.get("http://localhost:3000/api/shops/"),
                        axios.get("https://www.pinsdlp.com/api/pins/"),
                        axios.get("https://www.pinsdlp.com/api/categories/"),
                        axios.get("https://www.pinsdlp.com/api/shops/"),
                    ]);
                const pins = pinsResponse.data.ListePins.map((pin) => {
                    const category =
                        categoriesResponse.data.ListeCategories.find(
                            (c) => c.id === pin.CategorieId
                        );
                    const shop = shopsResponse.data.ListeShops.find(
                        (s) => s.id === pin.ShopId
                    );

                    return {
                        ...pin,
                        categorie_name: category
                            ? category.categorie_name
                            : null,
                        shop_name: shop ? shop.shop_name : null,
                        shop_description: shop ? shop.shop_description : null,
                        shop_products: shop ? shop.shop_products : null,
                        shop_location_park: shop
                            ? shop.shop_location_park
                            : null,
                        shop_location_area: shop
                            ? shop.shop_location_area
                            : null,
                        shop_src: shop ? shop.shop_src : null,
                    };
                });
                context.commit("setPinsData", pins);
                // console.log(pins);
            } catch (error) {
                console.log(error);
            }
        },
    },
});

export default store;
