<template>
    <v-btn
        v-if="showScrollToTopButton"
        class="scroll-to-top-btn"
        position="static"
        @click="scrollToTop"
        color="transparent"
        flat
        :ripple="false"
    >
        <div class="ear"></div>
        <div class="head">
            <v-icon color="white" size="37" icon="$arrowUp"></v-icon>
        </div>
        <div class="ear"></div>
    </v-btn>
</template>

<script>
export default {
    name: "BackToTop",
    data() {
        return {
            showScrollToTopButton: false,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    computed: {},
    methods: {
        handleScroll() {
            this.showScrollToTopButton = window.scrollY > 100;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
};
</script>
<style scoped>
.scroll-to-top-btn {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 999;
}
.ear {
    width: 27px;
    height: 27px;
    background-color: black;
    position: absolute;
    top: -4px;
    border-radius: 50%;
}
.ear:first-child {
    left: 0px;
}
.ear:last-child {
    right: 0px;
}
.head {
    width: 40px;
    height: 40px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    left: 12px;
}
</style>
