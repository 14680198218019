import { createWebHistory, createRouter } from "vue-router";

// import Home from "../pages/Home.vue";
// import About from "../pages/About.vue";
// import MenuPins from "../pages/MenuPins.vue";
// import Pins from "../pages/Pins.vue";
// import Article from "../pages/Article.vue";
// import MenuArticles from "../pages/MenuArticles.vue";
// import SiteMap from "../pages/SiteMap.vue";
// import Mentions from "../pages/Mentions.vue";
// import NotFound from "../components/NotFound.vue";

// import Dashboard from "../admin/Dashboard.vue";
// import AddPins from "../admin/pins/AddPins.vue";
// import AddCategorie from "../admin/categories/AddCategorie.vue";
// import ListCategorie from "../admin/categories/ListCategorie.vue";
// import ListPins from "../admin/pins/ListPins.vue";
// import ModifyPins from "../admin/pins/ModifyPins.vue";
// import ListShop from "../admin/shops/ListShop.vue";
// import ModifyShop from "../admin/shops/ModifyShop.vue";
// import AddShop from "../admin/shops/AddShop.vue";
// import ListArticle from "../admin/articles/ListArticle.vue";
// import ModifyArticle from "../admin/articles/ModifyArticle.vue";
// import AddArticle from "../admin/articles/AddArticle.vue";
// import ListAffiche from "../admin/affiches/ListAffiche.vue";
// import ModifyAffiche from "../admin/affiches/ModifyAffiche.vue";
// import AddAffiche from "../admin/affiches/addAffiche.vue";
// import AdminLayout from "../admin/Layout.vue";
// import Login from "../auth/Login.vue";
// import Register from "../auth/Register.vue";
// import ModifyCategorie from "../admin/categories/ModifyCategorie.vue";
// import ForgotPassword from "../components/ForgotPassword.vue";
// import ProtectedRoute from "../components/ProtectedRoute.vue";
// import User from "../components/User.vue";

const Home = () => import("../pages/Home.vue");
const About = () => import("../pages/About.vue");
const MenuPins = () => import("../pages/MenuPins.vue");
const Pins = () => import("../pages/Pins.vue");
const Article = () => import("../pages/Article.vue");
const MenuArticles = () => import("../pages/MenuArticles.vue");
const SiteMap = () => import("../pages/SiteMap.vue");
const Mentions = () => import("../pages/Mentions.vue");
const MenuAffiches = () => import("../pages/MenuAffiches.vue");
const Affiche = () => import("../pages/Affiche.vue");
const NotFound = () => import("../components/NotFound.vue");

const routes = [
    //{
    //     path: "/admin",
    //     component: ProtectedRoute,
    //     children: [
    //         {
    //             path: "",
    //             name: "AdminLayout",
    //             component: AdminLayout,
    //             children: [
    //                 {
    //                     path: "dashboard",
    //                     name: "Dashboard",
    //                     component: Dashboard,
    //                 },
    //                 {
    //                     path: "pins/listpins",
    //                     name: "ListPins",
    //                     component: ListPins,
    //                 },
    //                 {
    //                     path: "pins/addpins",
    //                     name: "AddPins",
    //                     component: AddPins,
    //                 },
    //                 {
    //                     path: "pins/listpins/:id",
    //                     name: "ModifyPins",
    //                     component: ModifyPins,
    //                 },
    //                 {
    //                     path: "categories/listcategories",
    //                     name: "ListCategorie",
    //                     component: ListCategorie,
    //                 },
    //                 {
    //                     path: "categories/addcategories",
    //                     name: "AddCategorie",
    //                     component: AddCategorie,
    //                 },
    //                 {
    //                     path: "categories/listcategories/:id",
    //                     name: "ModifyCategorie",
    //                     component: ModifyCategorie,
    //                 },
    //                 {
    //                     path: "shops/listshops",
    //                     name: "ListShop",
    //                     component: ListShop,
    //                 },
    //                 {
    //                     path: "shops/addshops",
    //                     name: "AddShop",
    //                     component: AddShop,
    //                 },
    //                 {
    //                     path: "shops/listshops/:id",
    //                     name: "ModifyShop",
    //                     component: ModifyShop,
    //                 },
    //                 {
    //                     path: "articles/listarticles",
    //                     name: "ListArticle",
    //                     component: ListArticle,
    //                 },
    //                 {
    //                     path: "articles/addarticles",
    //                     name: "AddArticle",
    //                     component: AddArticle,
    //                 },
    //                 {
    //                     path: "articles/listarticles/:id",
    //                     name: "ModifyArticle",
    //                     component: ModifyArticle,
    //                 },
    //                 {
    //                     path: "affiches/listaffiches",
    //                     name: "ListAffiche",
    //                     component: ListAffiche,
    //                 },
    //                 {
    //                     path: "affiches/addaffiches",
    //                     name: "AddAffiche",
    //                     component: AddAffiche,
    //                 },
    //                 {
    //                     path: "affiches/listaffiches/:id",
    //                     name: "ModifyAffiche",
    //                     component: ModifyAffiche,
    //                 },
    //             ],
    //         },
    //     ],
    // },

    // { path: "/login", name: "Login", component: Login },
    // { path: "/register", name: "Register", component: Register },
    // {
    //     path: "/forgot-password",
    //     name: "ForgotPassword",
    //     component: ForgotPassword,
    // },
    // {
    //     path: "/user",
    //     name: "user",
    //     component: User,
    // },
    // {
    //     path: "/checkauth",
    //     name: "checkauth",
    // },

    {
        path: "/",
        name: "Votre plateforme de pin's Disneyland Paris",
        component: Home,
    },
    {
        path: "/mentions",
        name: "Mentions",
        component: Mentions,
    },
    {
        path: "/plan-du-site",
        name: "SiteMap",
        component: SiteMap,
    },
    {
        path: "/a-propos",
        name: "Tout ce que vous devez savoir sur l'univers du pin's",
        component: About,
    },
    {
        path: "/menu-articles",
        name: "Les dernières tendances et actualités sur les pin's Disneyland Paris",
        component: MenuArticles,
    },
    {
        path: "/articles/:name",
        name: "Article",
        component: Article,
    },
    {
        path: "/menu-pins",
        name: "Explorez les collections de pin's Disney de Disneyland Paris",
        component: MenuPins,
    },
    {
        path: "/pins/:name",
        name: "Pins",
        component: Pins,
    },
    {
        path: "/menu-affiches",
        name: "Explorez les affiches de pin's Disney de Disneyland Paris",
        component: MenuAffiches,
    },
    {
        path: "/menu-affiches/:name",
        name: "Affiches",
        component: Affiche,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Erreur 404 page introuvable",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});
router.afterEach((to) => {
    document.title =
        "PinsDLP - " + to.name ||
        "PinsDLP - Votre plateforme de pin's Disneyland Paris";
});

export default router;
